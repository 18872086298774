import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'

import { type BagelOptions, BagelVue, ModalPlugin, RouterWrapper } from '@bagelink/vue'
import Toast, { POSITION, type PluginOptions, useToast } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import '@bagelink/vue/dist/style.css'
import '@/styles/theme.css'

import App from './App.vue'
import router from './router'

import en from './lang/en'
import he from './lang/he'
import ru from './lang/ru'
import { initSentry } from '@/sentry'

const app = createApp(App)

app.use(router)

const pinia = createPinia()
pinia.use(({ store }) => {
	store.router = markRaw(router)
})

const defaultLang = 'he'

const i18n = createI18n({
	locale: defaultLang,
	legacy: false,
	messages: {
		en,
		he,
		ru,
	},
})

app.use(i18n)
const toastOptions: PluginOptions = {
	position: POSITION.TOP_RIGHT,
	icon: {
		iconClass: 'bgl_icon-font',
		iconChildren: 'info',
		iconTag: 'span',
	},
}
app.use(Toast, toastOptions)

const bagelConfig: BagelOptions = {
	language: defaultLang,
	availableLangs: ['he', 'en', 'ru'],
	defaultLang,
	host: import.meta.env.VITE_BAGEL_BASE_URL,
	onError: (err: any) => {
		useToast().error(`${'An Error Occurred'}: ${err?.response?.data?.detail || err.message}`)
	},
}
app.component('RouterWrapper', RouterWrapper)
app.use(ModalPlugin)
app.use(BagelVue, bagelConfig)
app.use(pinia)

initSentry(app)
app.mount('#app')
